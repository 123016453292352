.x.mandatory-scroll-snapping {
  scroll-snap-type: x proximity;
}

@media (max-width: 768px) {
  .x.mandatory-scroll-snapping.px-4 {
    scroll-padding-left: 16px;
  }
}

@media (min-width: 600px) {
  .x.mandatory-scroll-snapping.md\:px-8 {
    scroll-padding-left: 32px;
  }

  .x.mandatory-scroll-snapping.md\:px-4 {
    scroll-padding-left: 32px;
  }
}

@media (min-width: 1280px) {
  .x.mandatory-scroll-snapping.xl\:px-10 {
    scroll-padding-left: 40px;
  }

  .x.mandatory-scroll-snapping.xl\:px-12 {
    scroll-padding-left: 48px;
  }
}

.snap-initial {
  scroll-snap-align: start;
}

.snap-end {
  scroll-snap-align: end;
}
