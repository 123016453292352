.cardGroup {
  display: grid;
}

.cardGroup > .card {
  border: 0;
  box-shadow: none;
  padding: 0;
  padding: 24px 0;
  border-radius: 0;
}

.cardGroup > .card:not(:last-child) {
  border-bottom: 1px solid var(--color-coolgray-20);
}

.body {
  margin: 24px -24px;
}

.header {
  padding: 0px 24px 24px;
  margin: 0px -24px 24px;
  font-style: normal;
  color: var(--color-grayscale-100);
  display: grid;
  background-color: var(--color-grayscale-0);
}

.header.small {
  margin: 0px -24px 16px;
}

.header.none {
  margin: 0 -24px 0;
}

.header.border {
  border-bottom: 1px solid var(--color-coolgray-20);
}

.footer {
  padding: 24px 24px 0;
  margin: 24px -24px 0;
  display: grid;
  background-color: var(--color-grayscale-0);
}

.footer.border {
  border-top: 1px solid var(--color-coolgray-20);
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  transition: scale 0.5s ease-in-out;
  transform: scale(1);
  width: 24px;
  height: 24px;
}

.closeButton:hover {
  transform: scale(1.2);
}
